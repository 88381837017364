import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  TextField,
  CircularProgress,
  IconButton,
  Chip,
} from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import FrontendHeader from "../../Common/frontendHeader";
import Footer from "../../Common/footer";
import { useHistory } from "react-router-dom";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import {
  PROPERTY_DETAILS,
  LIST_SAVED_PROPERTIES,
  LIST_RELATED_PROPERTIES,
  PROPERTY_TOGGLE,
  LIST_PROPERTY,
} from "../../../Graghql/queries";
import { useParams } from "react-router-dom";
// import {
//   makeStyles,
// } from "@material-ui/core/styles";
import {
  ADD_INQUIRE,
  LEAD_ACTIVITY_ADD,
  UNSAVE_PROPERTY,
} from "../../../Graghql/mutations";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Slider from "react-slick";
import { toggleSnackbar } from "../../../RactiveVariables/index";
import Map from "./Map";
import { useReactiveVar } from "@apollo/client";
import { userDeatils, authVar } from "../../../RactiveVariables/index";
import Loader from "../../Common/Loader";
import { DialogBox } from "../../Common";
import AuthPopUp from "../../Login/AuthPopUp";
import moment from "moment";
function TexttView(props) {
  const [visible, setVisible] = useState(false);
  function formateText(text) {
    if (!visible) {
      if (text.length > 100) return text.slice(0, 100) + " ...";
      else return text.slice(0, 100);
    } else return text;
  }
  if (!props.text) return null;
  return (
    <div
      className="propertyDetialDescription"
      style={{ textOverflow: "ellipsis" }}
    >
      {formateText(props.text)}
      {props.text.length > 100 && (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          style={{ color: visible ? "red" : "green" }}
          onClick={() => setVisible(!visible)}
        >
          {visible ? " Less" : " More"}
        </a>
      )}
    </div>
  );
}
// const useStylesReddit = makeStyles((theme) => ({
//   root: {
//     border: "1px solid #dad5d5",
//     overflow: "hidden",
//     borderRadius: 0,
//     backgroundColor: "#fff",
//     "&:hover": {
//       backgroundColor: "#fff",
//     },
//     "&$focused": {
//       backgroundColor: "#fff",

//       borderColor: theme.palette.primary.main,
//     },
//   },
//   focused: {},
// }));

// function RedditTextField(props) {
//   const classes = useStylesReddit();

//   return (
//     <TextField InputProps={{ classes, disableUnderline: true }} {...props} />
//   );
// }
const schema = yup.object().shape({
  FirstName: yup.string().required("First Name is required"),
  //.matches(/^[a-zA-Z]+$/, "Must be characters"),
  LastName: yup.string().required("Last Name is required"),
  //.matches(/^[a-zA-Z]+$/, "Must be characters"),
  Phone: yup
    .string()
    .test("Phone", "Must be 10 digits", (value) => {
      if (value && value.length < 12) return false;
      else return true;
    })
    .nullable(),
  // Phone: yup
  //   .string().matches(/^\d{3}-\d{3}-\d{4}$/, "Must be 10 digits"),
  // .test("Phone", "Must be a number", (value) => {
  //   if (value && isNaN(value.replace(/[-]+/g, ""))) return false;
  //   else return true;
  // }),
  // .matches(/^\d{3}-\d{3}-\d{4}$/, "Must be 10 digits"),
  //Comments:yup.string().required("Comment is required"),
  Email: yup.string().required("Email is required").email(),
});
const PropertyDetail = () => {
  const user = useReactiveVar(userDeatils);

  const auth = useReactiveVar(authVar);
  const location = useLocation();
  const states = location.state;
  const history = useHistory();
  const { area, slug } = useParams();
  const [id, setId] = useState(null);
  const [phone, setPhone] = useState(user?.data?.Phone ?? "");
  const [toggle, setToggle] = useState(true);
  const [openUnsaveMsg, setOpenUnsaveMsg] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [prePosition, setPrePosition] = useState("");
  const [count, setCount] = useState(0);
  const [locationData, setLocationData] = useState("");
  const [getpropertyDetails, { data, loading }] = useLazyQuery(
    PROPERTY_DETAILS,
    {
      fetchPolicy: "network-only",
      onCompleted: (res) => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      },
    }
  );
  React.useEffect(() => {
    const initializeFWDRL = () => {
      if (window.FWDRL) {
        new window.FWDRL({
          //main settings
          mainFolderPath: "../content",
          skinPath: "modern_skin_dark",
          facebookAppId: "213684265480896",
          rightClickContextMenu: "default",
          buttonsAlignment: "in",
          useDeepLinking: "no",
          useAsModal: "no",
          slideShowAutoPlay: "no",
          addKeyboardSupport: "yes",
          showCloseButton: "yes",
          showShareButton: "yes",
          showZoomButton: "yes",
          showSlideShowButton: "yes",
          showSlideShowAnimation: "yes",
          showNextAndPrevButtons: "yes",
          showNextAndPrevButtonsOnMobile: "yes",
          buttonsHideDelay: 10,
          slideShowDelay: 4,
          defaultItemWidth: 640,
          defaultItemHeight: 480,
          itemOffsetHeight: 50,
          spaceBetweenButtons: 8,
          buttonsOffsetIn: 5,
          buttonsOffsetOut: 5,
          itemBorderSize: 4,
          itemBorderRadius: 2,
          backgroundOpacity: 0.7,
          itemBoxShadow: "none",
          itemBackgroundColor: "#333333",
          itemBorderColor: "#FFFFFF",
          backgroundColor: "#000000",
          //thumbnails settings
          showThumbnails: "yes",
          showThumbnailsHideOrShowButton: "yes",
          showThumbnailsByDefault: "yes",
          showThumbnailsOverlay: "yes",
          showThumbnailsSmallIcon: "yes",
          thumbnailsHoverEffect: "scale",
          thumbnailsImageHeight: 80,
          thumbnailsBorderSize: 4,
          thumbnailsBorderRadius: 0,
          spaceBetweenThumbnailsAndItem: 0,
          thumbnailsOffsetBottom: 0,
          spaceBetweenThumbnails: 2,
          thumbnailsOverlayOpacity: 0.6,
          thumbnailsOverlayColor: "#000000",
          thumbnailsBorderNormalColor: "#FFFFFF",
          thumbnailsBorderSelectedColor: "#FFFFFF",
          //description settings
          descriptionWindowPosition: "bottom",
          showDescriptionButton: "yes",
          showDescriptionByDefault: "yes",
          descriptionWindowAnimationType: "motion",
          descriptionWindowBackgroundColor: "#FFFFFF",
          descriptionWindowBackgroundOpacity: 0.9,
          //video & audio players settings
          useVideo: "yes",
          useAudio: "yes",
          videoShowFullScreenButton: "yes",
          addVideoKeyboardSupport: "yes",
          nextVideoOrAudioAutoPlay: "yes",
          videoAutoPlay: "no",
          videoLoop: "no",
          audioAutoPlay: "no",
          audioLoop: "no",
          videoControllerHideDelay: 3,
          videoControllerHeight: 41,
          audioControllerHeight: 44,
          startSpaceBetweenButtons: 7,
          vdSpaceBetweenButtons: 9,
          mainScrubberOffestTop: 14,
          scrubbersOffsetWidth: 1,
          audioScrubbersOffestTotalWidth: 4,
          timeOffsetLeftWidth: 5,
          timeOffsetRightWidth: 3,
          volumeScrubberWidth: 80,
          volumeScrubberOffsetRightWidth: 0,
          videoControllerBackgroundColor: "#FFFFFF",
          videoPosterBackgroundColor: "#0099FF",
          audioControllerBackgroundColor: "#FFFFFF",
          timeColor: "#000000",
        });
      } else {
        // FWDRL library is not loaded, load it dynamically
        const script = document.createElement("script");
        script.src = "/java/FWDRL.js"; // Adjust the path based on your project structure
        script.async = true;
        script.onload = () => {
          // FWDRL library is now loaded, initialize it
          new window.FWDRL({
            //main settings
            mainFolderPath: "../content",
            skinPath: "modern_skin_dark",
            facebookAppId: "213684265480896",
            rightClickContextMenu: "default",
            buttonsAlignment: "in",
            useDeepLinking: "no",
            useAsModal: "no",
            slideShowAutoPlay: "no",
            addKeyboardSupport: "yes",
            showCloseButton: "yes",
            showShareButton: "yes",
            showZoomButton: "yes",
            showSlideShowButton: "yes",
            showSlideShowAnimation: "yes",
            showNextAndPrevButtons: "yes",
            showNextAndPrevButtonsOnMobile: "yes",
            buttonsHideDelay: 10,
            slideShowDelay: 4,
            defaultItemWidth: 640,
            defaultItemHeight: 480,
            itemOffsetHeight: 50,
            spaceBetweenButtons: 8,
            buttonsOffsetIn: 5,
            buttonsOffsetOut: 5,
            itemBorderSize: 4,
            itemBorderRadius: 2,
            backgroundOpacity: 0.7,
            itemBoxShadow: "none",
            itemBackgroundColor: "#333333",
            itemBorderColor: "#FFFFFF",
            backgroundColor: "#000000",
            //thumbnails settings
            showThumbnails: "yes",
            showThumbnailsHideOrShowButton: "yes",
            showThumbnailsByDefault: "yes",
            showThumbnailsOverlay: "yes",
            showThumbnailsSmallIcon: "yes",
            thumbnailsHoverEffect: "scale",
            thumbnailsImageHeight: 80,
            thumbnailsBorderSize: 4,
            thumbnailsBorderRadius: 0,
            spaceBetweenThumbnailsAndItem: 0,
            thumbnailsOffsetBottom: 0,
            spaceBetweenThumbnails: 2,
            thumbnailsOverlayOpacity: 0.6,
            thumbnailsOverlayColor: "#000000",
            thumbnailsBorderNormalColor: "#FFFFFF",
            thumbnailsBorderSelectedColor: "#FFFFFF",
            //description settings
            descriptionWindowPosition: "bottom",
            showDescriptionButton: "yes",
            showDescriptionByDefault: "yes",
            descriptionWindowAnimationType: "motion",
            descriptionWindowBackgroundColor: "#FFFFFF",
            descriptionWindowBackgroundOpacity: 0.9,
            //video & audio players settings
            useVideo: "yes",
            useAudio: "yes",
            videoShowFullScreenButton: "yes",
            addVideoKeyboardSupport: "yes",
            nextVideoOrAudioAutoPlay: "yes",
            videoAutoPlay: "no",
            videoLoop: "no",
            audioAutoPlay: "no",
            audioLoop: "no",
            videoControllerHideDelay: 3,
            videoControllerHeight: 41,
            audioControllerHeight: 44,
            startSpaceBetweenButtons: 7,
            vdSpaceBetweenButtons: 9,
            mainScrubberOffestTop: 14,
            scrubbersOffsetWidth: 1,
            audioScrubbersOffestTotalWidth: 4,
            timeOffsetLeftWidth: 5,
            timeOffsetRightWidth: 3,
            volumeScrubberWidth: 80,
            volumeScrubberOffsetRightWidth: 0,
            videoControllerBackgroundColor: "#FFFFFF",
            videoPosterBackgroundColor: "#0099FF",
            audioControllerBackgroundColor: "#FFFFFF",
            timeColor: "#000000",
          });
        };
        document.head.appendChild(script);
      }
    };
    if (data?.propertyDetails) {
      initializeFWDRL();
    }
    // setPhone(user?.data?.Phone);
  }, [data?.propertyDetails]);

  const {
    handleSubmit,
    formState: { errors },
    reset,
    register,
  } = useForm({ defaultValues: user?.data, resolver: yupResolver(schema) });

  const [addInquire, { loading: inquireLoading }] = useMutation(ADD_INQUIRE);
  const [savedProperty] = useMutation(LEAD_ACTIVITY_ADD, {
    refetchQueries: ["listSavedProperties"],
  });
  const [unSavedProperty] = useMutation(UNSAVE_PROPERTY, {
    refetchQueries: ["listSavedProperties"],
  });
  // eslint-disable-next-line no-unused-vars
  const { data: toggleData, loading: toggleLoading } = useQuery(
    PROPERTY_TOGGLE,
    {
      fetchPolicy: "network-only",
      variables: { args: area + "/" + slug, argsType: "slug" },
      onCompleted: (res) => {
        setId(res?.propertyToggle?._id);
        getpropertyDetails({
          variables: { propertyId: res?.propertyToggle?._id },
        });
        getRelatedProperty({
          variables: { id: res?.propertyToggle?._id, type: "property" },
        });
      },
    }
  );

  const [getRelatedProperty, { data: listRelatedProperty }] = useLazyQuery(
    LIST_RELATED_PROPERTIES
  );
  useEffect(() => {
    if (user?.data?._id) {
      savedPropertiesList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.data?._id]);
  const { data: nextPropertyArray } = useQuery(LIST_PROPERTY, {
    variables: {
      locations: states?.searchObj?.area,
      subArea: states?.searchObj?.subArea,
      price: states?.searchObj?.price,
      rentalPeriods: states?.searchObj?.period,
      listBed: parseFloat(states?.searchObj?.bed),
      listBath: parseFloat(states?.searchObj?.bath),
      amenities: states?.searchObj?.amenities,
      listWater: states?.searchObj?.listWater,
      listTennis: states?.searchObj?.listTennis,
      listPool: states?.searchObj?.listPool,
      listPet: states?.searchObj?.listPet,
      listStyle: states?.searchObj?.listStyle,
      keywordSearch: states?.searchObj?.keywordSearch,
      hidePrivatePpty: !auth.auth,
      limit: 50,
      skip: 0,
    },
  });
  const [savedPropertiesList, { data: listSavedProperty }] = useLazyQuery(
    LIST_SAVED_PROPERTIES,
    {
      fetchPolicy: "network-only",
      variables: { userId: user?.data?._id },
    }
  );

  // const nextPropertyVariables = {
  //   area: states?.searchObj?.area,
  //   price: states?.searchObj?.price,
  //   rentalPeriods:states?.searchObj?.period ,
  //   listBed: parseFloat(states?.searchObj?.bed),
  //   listBath: parseFloat(states?.searchObj?.bath),
  //   amenities: states?.searchObj?.amenities,
  //   listWater: states?.searchObj?.listWater,
  //   listTennis: states?.searchObj?.listTennis,
  //   listPool:states?.searchObj?.listPool,
  //   listPet: states?.searchObj?.listPet,
  //   keywordSearch:states?.searchObj?.keywordSearch,
  //   subArea:states?.searchObj?.amenities ,
  // };

  let savedProperties =
    listSavedProperty &&
    listSavedProperty.listSavedProperties &&
    listSavedProperty.listSavedProperties.data;

  const phoneNumberFormat = (num) => {
    // let newNum = num.replace(/[-]+/g, "");
    let newNum = num.replace(/[^0-9]/g, "");
    let x;
    if (newNum.length <= 3) {
      x = newNum;
    } else if (newNum.length > 3 && newNum.length <= 6) {
      x = newNum.slice(0, 3) + "-" + newNum.slice(3, 6);
    } else {
      x =
        newNum.slice(0, 3) +
        "-" +
        newNum.slice(3, 6) +
        "-" +
        newNum.slice(6, 10);
    }
    return x;
  };
  const formatePrice = (value) => {
    return value.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };
  //const formatter= new Intl.NumberFormat('en-US',{style:"currency",currency:'USD',})
  const onSubmit = (params) => {
    params.PropertyId = id;
    try {
      addInquire({ variables: params })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Success",
            variant: "success",
          });
          auth.auth ? setPhone(user?.data?.Phone) : setPhone("");
          reset();
          setToggle(false);
          setTimeout(() => {
            setToggle(true);
          }, 10);
        })
        .catch((err) => {
          const message = err && err.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  };
  const handleSaveProperty = () => {
    // let arr = [];
    // data?.propertyDetails?.forEach((element) => {
    //   let ids = element?._id;
    //   arr.push(ids);
    // });
    // console.log("params.PropertyIDs", arr);
    try {
      savedProperty({
        variables: {
          UserId: user?.data?._id,
          LeadSrc: "Property Save",
          LeadType: "Save",
          //RootId:"0",
          Comment: "",
          PropertyIDs: [id],
        },
      })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Property saved",
            variant: "success",
          });
        })
        .catch((err) => {
          const message = err && err.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  };
  const handleUnSaveProperty = () => {
    try {
      unSavedProperty({
        variables: {
          userId: user?.data?._id,
          propertyId: id,
        },
      })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Property unsaved",
            variant: "success",
          });
        })
        .catch((err) => {
          const message = err && err.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  };
  const phoneNumberRegister = register("Phone");

  function isSaved() {
    if (savedProperties) {
      let position = savedProperties.findIndex(
        (element) => element.PropertyId === id
      );
      if (position !== -1) return true;
      else return false;
    }
  }
  function isSavedButton() {
    if (savedProperties) {
      let position = savedProperties.findIndex(
        (element) => element.PropertyId === id
      );
      if (position !== -1) return null;
      else return "";
    }
  }
  // eslint-disable-next-line no-unused-vars
  function formateTaxMap(value) {
    if (
      value?.Value1 &&
      value?.Value2 &&
      value?.Value3 &&
      value?.Value4 &&
      value?.Value5 &&
      value?.Value6 &&
      value?.Value7
    )
      return `${value?.Value1}/${value?.Value2}/${value?.Value3}/${value?.Value4}/${value?.Value5}/${value?.Value6}/${value?.Value7}`;
  }
  function handleNextClick() {
    let position = nextPropertyArray?.propertyList?.data.findIndex(
      (element) => element._id && element._id === id
    );
    setPrePosition(position);
    setCount(count + 1);
    setLocationData("searchList");
    if (position > -1) {
      // history.push(
      //   `/property-detail/${
      //     nextPropertyArray?.listAllProperties?.data[position + 1]?._id
      //   };${linkData.split(";")[1]};${linkData.split(";")[2]};${
      //     linkData.split(";")[3]
      //   };${linkData.split(";")[4]};${linkData.split(";")[5]}`
      // );
      if (nextPropertyArray?.propertyList?.data[position + 1]?.PropertySlug)
        history.push({
          pathname: `/${
            nextPropertyArray?.propertyList?.data[position + 1]?.PropertySlug
          }`,
          state: { linkData: "searchList", searchObj: states?.searchObj },
        });
    }
  }
  function handlePreviousClick() {
    let position = nextPropertyArray?.propertyList?.data.findIndex(
      (element) => element._id && element._id === id
    );
    setPrePosition(position);
    setCount(count - 1);
    setLocationData("searchList");
    if (position > -1) {
      // history.push(
      //   `/property-detail/${
      //     nextPropertyArray?.listAllProperties?.data[position + 1]?._id
      //   };${linkData.split(";")[1]};${linkData.split(";")[2]};${
      //     linkData.split(";")[3]
      //   };${linkData.split(";")[4]};${linkData.split(";")[5]}`
      // );
      if (nextPropertyArray?.propertyList?.data[position - 1]?.PropertySlug)
        history.push({
          pathname: `/${
            nextPropertyArray?.propertyList?.data[position - 1]?.PropertySlug
          }`,
          state: { linkData: "searchList", searchObj: states?.searchObj },
        });
    }
  }
  function getFeaturedImage(images) {
    if (images) {
      let vidPosition = images.findIndex((element) => element.VideoLink);
      if (vidPosition >= 0) {
        if (images[vidPosition]?.VideoLink.includes("youtube.com"))
          return {
            src:
              "https://img.youtube.com/vi/" +
              images[vidPosition]?.VideoLink.match(
                /(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/
              )[1] +
              "/hqdefault.jpg",
          };
        else
          return {
            src: "https://www.logolynx.com/images/logolynx/f6/f69e35d46e76c23940ef01eadc5bed3a.jpeg",
            dropBoxFlag: true,
            url: images[vidPosition]?.VideoLink,
          };
      } else {
        let position = images.findIndex((element) => element.Featured === true);
        if (position !== -1) {
          if (images[position]?.FileUrl)
            return { src: images[position]?.FileUrl };
          else return { src: "../assets/images/no-image.jpg" };
        } else return { src: "../assets/images/no-image.jpg" };
      }
    }
  }
  function getVideos(videos) {
    if (videos) {
      let position = videos.findIndex((element) => element.VideoLink);

      if (position === 0 || position === -1) {
        return videos;
      } else {
        let arr;
        let value;
        arr = [...videos];
        value = arr?.[position];
        arr[position] = arr[0];
        arr[0] = value;
        if (!arr[0]?.VideoLink.includes("youtube.com")) {
          let obj = { ...arr[0] };
          obj["dropBoxFlag"] = true;
          arr[0] = obj;
        }
        return arr;
      }
    } else return [];
  }

  // const listRelateProperty =
  //   listRelatedProperty &&
  //   listRelatedProperty.listRelatedProperties &&
  //   listRelatedProperty.listRelatedProperties.data;
  //   console.log("listRelatedProperty",listRelateProperty)

  var homeSlider = {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 3,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    // eslint-disable-next-line no-dupe-keys
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  // eslint-disable-next-line no-unused-vars
  function PreviousArrow(props) {
    const { onClick } = props;
    return (
      <img
        alt="prev"
        onClick={onClick}
        className="slick-prev"
        src="../assets/images/back.svg"
      />
    );
  }
  // eslint-disable-next-line no-unused-vars
  function NextArrow(props) {
    const { onClick } = props;
    return (
      <img
        alt="next"
        onClick={onClick}
        className="slick-next"
        src="../assets/images/forward.svg"
      />
    );
  }

  // eslint-disable-next-line no-unused-vars
  const NumberChecking = (e) => {
    const re = /^[0-9\b]+$/;
    if (e === "" || re.test(e)) {
      return e;
    } else {
      let newVal = e.replace(/\D/g, "");
      return newVal;
    }
  };
  function convertTimeStamp(date) {
    return moment(new Date(Number(date))).format("MM/DD/YYYY");
  }
  function getAltTag(item) {
    return `${item?.PID ?? ""}-${item?.ListBathRoom ?? ""}Bath-${
      item?.ListBedRoom ?? ""
    }Bed-${item?.Area ?? ""}`;
  }
  return (
    <div>
      <DialogBox
        open={openUnsaveMsg}
        setOpen={setOpenUnsaveMsg}
        handlePositiveBtn={handleUnSaveProperty}
        title={"Unsave Property?"}
        text={"Are you sure you want to Unsave the property?"}
      />
      <FrontendHeader hideDrawer={true} />
      <Loader open={loading || toggleLoading} />
      <div className="propertyDetail">
        <div className="propertyDetailHeader">
          <div className="d-flex align-items-center">
            {/* {user?.data?.Role != "admin" && ( */}
            {/* {!document.referrer===''?  <Link
              to="#"
              // onClick={() => history.push("/properties")}
              onClick={() => history.push("/")}
              className="back-btn"
            >
              <img
                src="../assets/images/icon-left-arrow.svg"
                className="imgfluid"
                alt="Icon"
              />{" "}
              <font className="d-none d-md-block">Back</font>
            </Link>
            : */}
            {location?.state?.linkData === "searchList" ||
            locationData === "searchList" ? (
              <Link
                to={{
                  pathname: "/searchlistings/page1",
                  searchObj: states?.searchObj,
                }}
                // to="#"
                // onClick={() => history.push("/properties")}
                // onClick={() => window.history.back()}
                className="back-btn"
              >
                <img
                  src="../assets/images/icon-left-arrow.svg"
                  className="imgfluid"
                  alt="Icon"
                />{" "}
                <font className="d-none d-md-block">Results</font>
              </Link>
            ) : history.length === 1 ? (
              <Link
                to="#"
                // onClick={() => history.push("/properties")}
                onClick={() => history.push("/")}
                className="back-btn"
              >
                <img
                  src="../assets/images/icon-left-arrow.svg"
                  className="imgfluid"
                  alt="Icon"
                />{" "}
                <font className="d-none d-md-block">Back</font>
              </Link>
            ) : (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid, no-script-url
              <a
                // href="javascript:window.history.back();"
                href="#"
                onClick={(e) => {
                  e.preventDefault(); // Prevent default navigation behavior
                  window.history.back(); // Navigate back
                }}
                // to="#"
                // onClick={() => history.push("/properties")}
                // onClick={() => window.history.back()}
                className="back-btn"
              >
                <img
                  src="../assets/images/icon-left-arrow.svg"
                  className="imgfluid"
                  alt="Icon"
                />{" "}
                <font className="d-none d-md-block">Back</font>
              </a>
            )}
            {/* }  */}
            {/* )}  */}
            {user?.data?.Role === "admin" &&
            data?.propertyDetails?.City &&
            data?.propertyDetails?.PID ? (
              <div className="short-description-breadcumb short-description-breadcumb-admin">
                <ul className="propertyDetailBreadcumb">
                  <li>
                    <Link to="/">Home</Link>{" "}
                  </li>
                  <li>
                    <Link
                      to={{
                        pathname: `/${data?.propertyDetails?.City.toLowerCase().replace(
                          /\s+/g,
                          "-"
                        )}/page1`,
                        searchObj: { area: data?.propertyDetails?.City },
                      }}
                    >
                      {data?.propertyDetails?.City &&
                        data?.propertyDetails?.City?.charAt(0).toUpperCase() +
                          data?.propertyDetails?.City?.slice(1).toLowerCase()}
                    </Link>{" "}
                  </li>
                  <li>
                    #{data?.propertyDetails?.PID && data?.propertyDetails?.PID}
                  </li>
                </ul>
                <h2>
                  {data?.propertyDetails?.AdTitle &&
                    data?.propertyDetails?.AdTitle}
                  {/* {data?.propertyDetails?.AdTitle &&
                    data?.propertyDetails?.AdTitle?.charAt(0).toUpperCase() +
                    data?.propertyDetails?.AdTitle?.slice(1).toLowerCase()} */}
                </h2>
              </div>
            ) : (
              <div className="short-description-breadcumb">
                {data?.propertyDetails?.City && data?.propertyDetails?.PID && (
                  <ul className="propertyDetailBreadcumb">
                    <li>
                      <Link to="/">Home</Link>{" "}
                    </li>
                    <li>
                      <Link
                        to={{
                          pathname: `/${data?.propertyDetails?.City.toLowerCase().replace(
                            /\s+/g,
                            "-"
                          )}/page1`,
                          searchObj: { area: data?.propertyDetails?.City },
                        }}
                      >
                        {data?.propertyDetails?.City &&
                          data?.propertyDetails?.City?.charAt(0).toUpperCase() +
                            data?.propertyDetails?.City?.slice(1).toLowerCase()}
                      </Link>{" "}
                    </li>
                    <li>
                      #
                      {data?.propertyDetails?.PID && data?.propertyDetails?.PID}
                    </li>
                  </ul>
                )}
                <h2>
                  {data?.propertyDetails?.AdTitle &&
                    data?.propertyDetails?.AdTitle}
                  {/* {data?.propertyDetails?.AdTitle &&
                    data?.propertyDetails?.AdTitle?.charAt(0).toUpperCase() +
                    data?.propertyDetails?.AdTitle?.slice(1).toLowerCase()} */}
                </h2>
              </div>
            )}
          </div>

          <div className="propertyDetailHeaderButton">
            {(user?.data?.Role === "owner" ||
              user?.data?.Role === "renter") && (
              <>
                <Button
                  //disabled={isSaved()}
                  variant="outlined"
                  className="me-2"
                  style={{ color: "#7f7f7f" }}
                  //type="submit"
                  onClick={() => {
                    !auth.auth
                      ? document
                          .getElementById("frontend-header-login-btn")
                          .click()
                      : isSaved()
                      ? setOpenUnsaveMsg(true)
                      : handleSaveProperty();
                  }}
                >
                  {isSaved() ? (
                    <FavoriteIcon color="primary" />
                  ) : (
                    // <img
                    //   src="../assets/images/icon-fav.png"
                    //   className="imgfluid me-2"
                    //   alt="Icon"
                    // />
                    <FavoriteBorderIcon color="primary" />
                  )}
                  {isSavedButton()}
                </Button>
              </>
            )}
            {(location?.state?.linkData === "searchList" ||
              locationData === "searchList") && (
              <>
                <Button
                  variant="outlined"
                  color="primary"
                  disabled={count <= 0}
                  onClick={handlePreviousClick}
                >
                  Previous
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  className="ms-2"
                  onClick={handleNextClick}
                >
                  Next
                </Button>
              </>
            )}
          </div>
        </div>
        <div className="propertyDetailWrap">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="propertyImageList">
                  <div className="featured-propertyImageList">
                    <label className="pd-pid">
                      PID #
                      {data?.propertyDetails?.PID && data?.propertyDetails?.PID}
                    </label>
                    {getFeaturedImage(data?.propertyDetails?.Images)
                      ?.dropBoxFlag ? (
                      <Link
                        to={
                          getFeaturedImage(data?.propertyDetails?.Images)?.url
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={
                            data?.propertyDetails?.Images &&
                            getFeaturedImage(data?.propertyDetails?.Images)?.src
                          }
                          alt={getAltTag(data?.propertyDetails)}
                        />
                      </Link>
                    ) : (
                      <img
                        onClick={() => {
                          if (
                            getFeaturedImage(data?.propertyDetails?.Images)
                              ?.src !== "../assets/images/no-image.jpg"
                          )
                            window.FWDRL.show("mixedPlaylist", 0);
                        }}
                        src={
                          data?.propertyDetails?.Images &&
                          getFeaturedImage(data?.propertyDetails?.Images)?.src
                        }
                        alt={getAltTag(data?.propertyDetails)}
                      />
                    )}
                  </div>

                  <ul className="propertyImageThumbnail">
                    {getVideos(data?.propertyDetails?.Images).map(
                      (item, index) => {
                        if (index > 5) return null;
                        return (
                          (item.Featured === false ||
                            data?.propertyDetails?.Images.findIndex(
                              (element) => element.VideoLink
                            ) > -1) &&
                          item.FileUrl != null &&
                          (index < 5 ? (
                            <li
                              onClick={() =>
                                window.FWDRL.show("mixedPlaylist", index)
                              }
                            >
                              {" "}
                              <img src={item.FileUrl} alt="Img" />
                              {/* <p>{item?.Title}</p> */}
                            </li>
                          ) : (
                            <li
                              onClick={() =>
                                window.FWDRL.show("mixedPlaylist", index)
                              }
                              className="more-img"
                            >
                              {/* <img src={item.FileUrl} alt="Img" /> */}
                              <label>
                                +{data?.propertyDetails?.Images.length - 5}
                              </label>
                            </li>
                          ))
                        );
                      }
                    )}
                    {/* <li  onClick={() => window.FWDRL.show('mixedPlaylist', 2)}> <img src="../assets/images/contact-address-bg.jpg" alt="Img"/></li>
                                    <li  onClick={() => window.FWDRL.show('mixedPlaylist', 3)}> <img src="../assets/images/contact-address-bg.jpg" alt="Img"/></li>
                                   <li  onClick={() => window.FWDRL.show('mixedPlaylist', 4)}> <img src="../assets/images/contact-address-bg.jpg" alt="Img"/></li> */}
                  </ul>
                  <ul id="mixedPlaylist" style={{ display: "none" }}>
                    {data?.propertyDetails?.Images &&
                      getVideos(data?.propertyDetails?.Images).map(
                        (item, index) =>
                          !item.dropBoxFlag && (
                            <li
                              data-url={
                                item.VideoLink ? item.VideoLink : item.FileUrl
                              }
                              data-thumbnail-path={
                                item.VideoLink
                                  ? "https://img.youtube.com/vi/" +
                                    item.VideoLink.match(
                                      /(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/
                                    )[1] +
                                    "/hqdefault.jpg"
                                  : item.FileUrl
                              }
                            >
                              <p>{item?.Title}</p>
                            </li>
                          )
                      )}
                    {/* <li data-url="../assets/images/contact-address-bg.jpg" data-thumbnail-path="../assets/images/contact-address-bg.jpg">
                                        <p>Item description here!</p>
                                    </li>
                                    <li data-url="../assets/images/contact-address-bg.jpg" data-thumbnail-path="../assets/images/contact-address-bg.jpg">
                                        <p>Item description here!</p>
                                    </li>
                                    <li data-url="../assets/images/contact-address-bg.jpg" data-thumbnail-path="../assets/images/contact-address-bg.jpg">
                                        <p>Item description here!</p>
                                    </li>
                                    <li data-url="../assets/images/contact-address-bg.jpg" data-thumbnail-path="../assets/images/contact-address-bg.jpg">
                                        <p>Item description here!</p>
                                    </li> */}
                  </ul>
                  {/* </> */}
                  {/* ))} */}
                </div>

                {data?.propertyDetails?.Latitude &&
                  parseFloat(data?.propertyDetails?.Longitude) && (
                    <Map
                      isMarkerShown
                      lat={parseFloat(data?.propertyDetails?.Latitude)}
                      lng={parseFloat(data?.propertyDetails?.Longitude)}
                      data={data?.propertyDetails}
                    />
                  )}
              </div>
              <div className="col-md-6">
                <div className="propertyFullDetail">
                  {/* {(data?.propertyDetails?.ListStyle ||
                    data?.propertyDetails?.ListBedRoom ||
                    data?.propertyDetails?.ListBathRoom) && ( */}
                  <h2>
                    {data?.propertyDetails?.AdTitle &&
                      data?.propertyDetails?.AdTitle}
                    {/* {data?.propertyDetails?.AdTitle &&
                    data?.propertyDetails?.AdTitle?.charAt(0).toUpperCase() +
                    data?.propertyDetails?.AdTitle?.slice(1).toLowerCase()} */}
                  </h2>
                  {/* <h2>
                    {data?.propertyDetails?.ListStyle &&
                      data?.propertyDetails?.ListStyle}{" "}
                    {data?.propertyDetails?.ListBedRoom &&
                      data?.propertyDetails?.ListBedRoom}
                    {"  "}Bedroom home with{" "}
                    {data?.propertyDetails?.ListBathRoom &&
                      data?.propertyDetails?.ListBathRoom}{" "}
                    Baths
                  </h2> */}
                  {/* )} */}
                  <ul className="iconDetail">
                    {data?.propertyDetails?.Area && (
                      <li>
                        <img
                          src="../assets/images/icon-location.svg"
                          className="imgfluid"
                          alt="Icon"
                        />
                        {data?.propertyDetails?.Area}
                      </li>
                    )}
                    {data?.propertyDetails?.LivingArea && (
                      <li>
                        <img
                          src="../assets/images/icon-sqft.svg"
                          className="imgfluid"
                          alt="Icon"
                        />
                        {data?.propertyDetails?.LivingArea} sqft
                      </li>
                    )}
                    {data?.propertyDetails?.ListBedRoom && (
                      <li>
                        <img
                          src="../assets/images/icon-bed.svg"
                          className="imgfluid"
                          alt="Icon"
                        />
                        {data?.propertyDetails?.ListBedRoom}
                      </li>
                    )}
                    {data?.propertyDetails?.ListBathRoom && (
                      <li>
                        <img
                          src="../assets/images/icon-bath.svg"
                          className="imgfluid"
                          alt="Icon"
                        />
                        {data?.propertyDetails?.ListBathRoom}
                      </li>
                    )}
                  </ul>
                  {/* <p>{data?.propertyDetails?.AdDescription.length>100?data?.propertyDetails?.AdDescription.substr(0, 100) + '...' : data?.propertyDetails?.AdDescription}</p> */}
                  <TexttView
                    text={
                      // data?.propertyDetails?.AdDescription?.charAt(
                      //   0
                      // ).toUpperCase() +
                      // data?.propertyDetails?.AdDescription?.slice(
                      //   1
                      // ).toLowerCase()
                      data?.propertyDetails?.AdDescription
                    }
                  />

                  {data?.propertyDetails?.ListMainAmenity && (
                    <ul className="propertyStyleTag">
                      {/* <li>{data?.propertyDetails?.ListWater}</li> */}
                      {data?.propertyDetails?.ListMainAmenity.map(
                        (item) => item?.Flag === true && <li>{item.Title}</li>
                      )}
                    </ul>
                  )}

                  <div className="propertyDetailPriceFeatures">
                    {data?.propertyDetails?.PriceData?.PriceDetails && (
                      <h3>
                        <img
                          src="../assets/images/icon-price.svg"
                          className="imgfluid"
                          alt="Icon"
                        />
                        Pricing & Availability
                      </h3>
                    )}
                    {data?.propertyDetails?.PriceData?.PriceDetails && (
                      <ul>
                        {data?.propertyDetails?.PriceData?.PriceDetails?.map(
                          (item) => (
                            <>
                              {(item.AvblStatus === "yes" ||
                                item.AvblStatus === "rented" ||
                                (item.AvblStatus === "no" &&
                                  item.Price > 0)) && (
                                <li>
                                  <label>
                                    {item.AvblStatus === "yes" && (
                                      <img
                                        src="../assets/images/green-check.svg"
                                        className="imgfluid"
                                        alt="Icon"
                                      />
                                    )}{" "}
                                    {item.AvblStatus === "rented" && (
                                      <img
                                        src="../assets/images/red-check.svg"
                                        className="imgfluid"
                                        alt="Icon"
                                      />
                                    )}
                                    {item.AvblStatus === "no" &&
                                      item.Price > 0 && (
                                        <img
                                          src="../assets/images/red-cross.svg"
                                          className="imgfluid"
                                          alt="Icon"
                                        />
                                      )}
                                    {item.RentalPeriod}
                                  </label>
                                  {/* <span>{formatter.format(item.Price)}</span> */}
                                  <span>${formatePrice(item.Price)}</span>
                                </li>
                              )}
                            </>
                          )
                        )}
                      </ul>
                    )}
                    {data?.propertyDetails?.PriceData?.PriceDetails && (
                      <h3>
                        <img
                          src="../assets/images/icon-price.svg"
                          className="imgfluid"
                          alt="Icon"
                        />
                        Short Term Price (Priced Weekly)
                      </h3>
                    )}
                    {data?.propertyDetails?.PriceData
                      ?.ShortTermPriceDetails && (
                      <ul>
                        {data?.propertyDetails?.PriceData?.ShortTermPriceDetails?.map(
                          (item) => (
                            <Chip
                              className="me-2 mt-2"
                              label={`${convertTimeStamp(
                                item?.DateFrom
                              )} - ${convertTimeStamp(item?.DateTo)} $${
                                item.Amount
                              }`}
                            />
                          )
                        )}
                      </ul>
                    )}
                    <h3>
                      <img
                        src="../assets/images/icon-home.svg"
                        className="imgfluid"
                        alt="Icon"
                      />
                      Features
                    </h3>
                    <ul className="mb-0">
                      {data?.propertyDetails?.Area && (
                        <li>
                          <label>Area</label>
                          <span>{data?.propertyDetails?.Area}</span>
                        </li>
                      )}
                      {data?.propertyDetails?.ListBedRoom && (
                        <li>
                          <label>Bed</label>
                          <span>{data?.propertyDetails?.ListBedRoom}</span>
                        </li>
                      )}
                      {data?.propertyDetails?.ListBathRoom && (
                        <li>
                          <label>Bath</label>
                          <span>{data?.propertyDetails?.ListBathRoom}</span>
                        </li>
                      )}
                      {data?.propertyDetails?.ListStyle && (
                        <li>
                          <label>Style</label>
                          <span>{data?.propertyDetails?.ListStyle}</span>
                        </li>
                      )}
                      {data?.propertyDetails?.ListFireplace && (
                        <li>
                          <label>Fireplace</label>
                          <span>{data?.propertyDetails?.ListFireplace}</span>
                        </li>
                      )}
                      {data?.propertyDetails?.LivingArea && (
                        <li>
                          <label>Living Area</label>
                          <span>{data?.propertyDetails?.LivingArea} sqft</span>
                        </li>
                      )}
                      {data?.propertyDetails?.YearBuilt && (
                        <li>
                          <label>Year built</label>
                          <span>{data?.propertyDetails?.YearBuilt}</span>
                        </li>
                      )}
                      {data?.propertyDetails?.ListHeat && (
                        <li>
                          <label>Heat</label>
                          <span>{data?.propertyDetails?.ListHeat}</span>
                        </li>
                      )}
                      {data?.propertyDetails?.ListGarage && (
                        <li>
                          <label>Garage</label>
                          <span>{data?.propertyDetails?.ListGarage}</span>
                        </li>
                      )}
                      {data?.propertyDetails?.ListBasement && (
                        <li>
                          <label>Basement</label>
                          <span>{data?.propertyDetails?.ListBasement}</span>
                        </li>
                      )}
                      {data?.propertyDetails?.ListAirCondition && (
                        <li>
                          <label>A/C</label>
                          <span>{data?.propertyDetails?.ListAirCondition}</span>
                        </li>
                      )}

                      {data?.propertyDetails?.LotSize && (
                        <li>
                          <label>Lot Size:</label>
                          <span>{data?.propertyDetails?.LotSize}</span>
                        </li>
                      )}
                      {data?.propertyDetails?.ListTennis && (
                        <li>
                          <label>Tennis</label>
                          <span>{data?.propertyDetails?.ListTennis}</span>
                        </li>
                      )}
                      {data?.propertyDetails?.ListWater && (
                        <li>
                          <label>Waterfront</label>
                          <span>{data?.propertyDetails?.ListWater}</span>
                        </li>
                      )}
                      {data?.propertyDetails?.RentalRegisteryId && (
                        <li>
                          <label>RR# </label>
                          <span>
                            {data?.propertyDetails?.RentalRegisteryId}
                          </span>
                        </li>
                      )}
                      {data?.propertyDetails?.ListPool && (
                        <li>
                          <label>Pool</label>
                          <span>{data?.propertyDetails?.ListPool}</span>
                        </li>
                      )}
                      {/* {data?.propertyDetails?.SuffCoTaxMap && (
                        <li>
                          <label>TaxMap</label>
                          <span>
                            {formateTaxMap(data?.propertyDetails?.SuffCoTaxMap)}
                          </span>
                        </li>
                      )} */}
                    </ul>
                  </div>
                </div>
                {user?.data?.Role !== "admin" &&
                  user?.data?.Role !== "agent" &&
                  user?.data?.Role !== "agentAdmin" &&
                  (auth.auth ? (
                    <div className="propertyEnquiry">
                      <h3>
                        I am Interested in this property PID#
                        {data?.propertyDetails?.PID &&
                          data?.propertyDetails?.PID}
                        {/* {data?.propertyDetails?.AdTitle &&
                          data?.propertyDetails?.AdTitle} */}
                        {/* {data?.propertyDetails?.AdTitle &&
                          data?.propertyDetails?.AdTitle?.charAt(
                            0
                          ).toUpperCase() +
                          data?.propertyDetails?.AdTitle?.slice(
                            1
                          ).toLowerCase()} */}
                      </h3>
                      {toggle && (
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="formTextBoxOuter">
                                <TextField
                                  label="First Name *"
                                  variant="filled"
                                  id="reddit-input"
                                  className="w-100"
                                  defaultValue={user?.data?.FirstName}
                                  {...register("FirstName", { required: true })}
                                />
                                {errors.FirstName && (
                                  <span className="errorMessage">
                                    {errors.FirstName.message}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="formTextBoxOuter">
                                <TextField
                                  label="Last Name *"
                                  variant="filled"
                                  id="reddit-input"
                                  className="w-100"
                                  defaultValue={user?.data?.LastName}
                                  {...register("LastName", { required: true })}
                                />
                                {errors.LastName && (
                                  <span className="errorMessage">
                                    {errors.LastName.message}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="formTextBoxOuter">
                                <TextField
                                  label="Email *"
                                  variant="filled"
                                  id="reddit-input"
                                  className="w-100"
                                  defaultValue={user?.data?.Email}
                                  {...register("Email", { required: true })}
                                />
                                {errors.Email && (
                                  <span className="errorMessage">
                                    {errors.Email.message}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="formTextBoxOuter">
                                {/* <PhoneInput
                                value = {phone}
                                specialLabel={""}
                                // {...register("HomePhone")}
                                defaultCountry="us"
                                country={"us"}
                                onChange={(e) => {
                                  setPhone(e)
                                  setValue("Phone", e);
                                }}
                              /> */}
                                {/* {errors && errors?.["Phone"] && (
                                <p className="errorMessage">{errors?.HomePhone?.message}</p>
                              )} */}
                                <TextField
                                  defaultValue={user?.data?.Phone}
                                  label="Phone"
                                  variant="filled"
                                  id="reddit-input"
                                  className="w-100"
                                  value={phone}
                                  {...phoneNumberRegister}
                                  onChange={(e) => {
                                    phoneNumberRegister.onChange(e);
                                    setPhone(phoneNumberFormat(e.target.value));
                                  }}
                                />
                                {errors.Phone && (
                                  <span className="errorMessage">
                                    {errors.Phone.message}
                                  </span>
                                )}
                              </div>
                            </div>
                            {/* <div className="col-md-6">
                            <div className="formTextBoxOuter">
                              <RedditTextField
                                label="I’m Intrested in"
                                variant="filled"
                                id="reddit-input"
                                className="w-100"
                                {...register("InterestedIn")}
                              />
                              {errors.InterestedIn && (
                                <span className="errorMessage">
                                  {errors.InterestedIn.message}
                                </span>
                              )}
                            </div>
                          </div> */}
                            <div className="col-md-12">
                              <div className="formTextBoxOuter">
                                <TextField
                                  label="Comments"
                                  variant="filled"
                                  id="reddit-input"
                                  className="w-100"
                                  //defaultValue={`I am interested in ${data?.propertyDetails?.AdTitle}`
                                  // data?.propertyDetails?.AdTitle?.charAt(
                                  //   0
                                  // ).toUpperCase() +
                                  //   data?.propertyDetails?.AdTitle?.slice(
                                  //     1
                                  //   ).toLowerCase()
                                  // }
                                  {...register("Comments")}
                                />
                                {errors.Comments && (
                                  <span className="errorMessage">
                                    {errors.Comments.message}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-12">
                              <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={loading}
                              >
                                {inquireLoading ? (
                                  <CircularProgress
                                    size="20px"
                                    color="inherit"
                                  />
                                ) : (
                                  "INQUIRE"
                                )}
                              </Button>
                            </div>
                          </div>
                        </form>
                      )}
                    </div>
                  ) : (
                    <div className="propertyEnquiry">
                      <h3>
                        I am Interested in{" "}
                        {data?.propertyDetails?.AdTitle &&
                          data?.propertyDetails?.AdTitle}
                        {/* {data?.propertyDetails?.AdTitle &&
                          data?.propertyDetails?.AdTitle?.charAt(
                            0
                          ).toUpperCase() +
                          data?.propertyDetails?.AdTitle?.slice(
                            1
                          ).toLowerCase()} */}
                      </h3>
                      {toggle && (
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="formTextBoxOuter">
                                <TextField
                                  label="First Name *"
                                  variant="filled"
                                  id="reddit-input"
                                  className="w-100"
                                  {...register("FirstName", { required: true })}
                                />
                                {errors.FirstName && (
                                  <span className="errorMessage">
                                    {errors.FirstName.message}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="formTextBoxOuter">
                                <TextField
                                  label="Last Name *"
                                  variant="filled"
                                  id="reddit-input"
                                  className="w-100"
                                  {...register("LastName")}
                                />
                                {errors.LastName && (
                                  <span className="errorMessage">
                                    {errors.LastName.message}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="formTextBoxOuter">
                                <TextField
                                  label="Email *"
                                  variant="filled"
                                  id="reddit-input"
                                  className="w-100"
                                  {...register("Email", { required: true })}
                                />
                                {errors.Email && (
                                  <span className="errorMessage">
                                    {errors.Email.message}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="formTextBoxOuter">
                                {/* <PhoneInput
                                specialLabel={""}
                                // {...register("HomePhone")}
                                defaultCountry="us"
                                country={"us"}
                                className="w-100"
                                onChange={(e) => {
                                  setValue("Phone", e);
                                }}
                              /> */}
                                <TextField
                                  label="Phone"
                                  variant="filled"
                                  id="reddit-input"
                                  className="w-100"
                                  value={phone}
                                  {...phoneNumberRegister}
                                  onChange={(e) => {
                                    phoneNumberRegister.onChange(e);
                                    setPhone(phoneNumberFormat(e.target.value));
                                  }}
                                />
                                {errors.Phone && (
                                  <span className="errorMessage">
                                    {errors.Phone.message}
                                  </span>
                                )}
                              </div>
                            </div>
                            {/* <div className="col-md-6">
                            <div className="formTextBoxOuter">
                              <RedditTextField
                                label="I’m Intrested in"
                                variant="filled"
                                id="reddit-input"
                                className="w-100"
                                {...register("InterestedIn")}
                              />
                              {errors.InterestedIn && (
                                <span className="errorMessage">
                                  {errors.InterestedIn.message}
                                </span>
                              )}
                            </div>
                          </div> */}
                            <div className="col-md-12">
                              <div className="formTextBoxOuter">
                                <TextField
                                  label="Comments"
                                  variant="filled"
                                  id="reddit-input"
                                  className="w-100"
                                  {...register("Comments")}
                                />
                                {errors.Comments && (
                                  <span className="errorMessage">
                                    {errors.Comments.message}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-12">
                              <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={loading}
                              >
                                {inquireLoading ? (
                                  <CircularProgress
                                    size="20px"
                                    color="inherit"
                                  />
                                ) : (
                                  "INQUIRE"
                                )}
                              </Button>
                            </div>
                          </div>
                        </form>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="homePropetry">
        <div className="container">
          {listRelatedProperty?.listRelatedProperties?.data.length > 0 && (
            <label style={{ fontSize: "20px", margin: "15px" }}>
              Similar Properties
            </label>
          )}
          {listRelatedProperty && (
            <Slider className="homeSlider similiar-slider" {...homeSlider}>
              {listRelatedProperty?.listRelatedProperties?.data &&
                listRelatedProperty?.listRelatedProperties?.data?.map(
                  (items, index) => (
                    <SimilarPropertyTile item={items} index={index} />
                  )
                )}
            </Slider>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default PropertyDetail;

function SimilarPropertyTile(props) {
  const user = useReactiveVar(userDeatils);
  const userRole = user?.data?.Role;
  const auth = useReactiveVar(authVar);
  const [savedProperty] = useMutation(LEAD_ACTIVITY_ADD, {
    refetchQueries: ["listSavedProperties"],
  });
  const [unSavedProperty] = useMutation(UNSAVE_PROPERTY, {
    refetchQueries: ["listSavedProperties"],
  });
  const [savedPropertiesList, { data: listSavedProperty }] = useLazyQuery(
    LIST_SAVED_PROPERTIES,
    {
      fetchPolicy: "network-only",
      variables: { userId: user?.data?._id },
    }
  );
  useEffect(() => {
    if (user?.data?._id) {
      savedPropertiesList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.data?._id]);
  const [openUnsaveMsg, setOpenUnsaveMsg] = useState(false);
  let savedProperties =
    listSavedProperty &&
    listSavedProperty.listSavedProperties &&
    listSavedProperty.listSavedProperties.data;
  console.log("savedProperties", savedProperties);
  // eslint-disable-next-line no-unused-vars
  const { id } = useParams();

  const { item } = props;

  const history = useHistory();
  const [openAuthPopUp, setOpenAuthPopUp] = React.useState(
    props?.location === "/login" ? true : false
  );
  // eslint-disable-next-line no-unused-vars
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    prevArrow: <PreviousArrow />,
    nextArrow: <NextArrow />,
  };
  function isSaved() {
    if (savedProperties) {
      let position = savedProperties.findIndex(
        (element) => element.PropertyId === item._id
      );
      if (position !== -1) return true;
      else return false;
    }
  }
  const handleSaveProperty = () => {
    // alert("hi");
    // let arr = [];
    // data?.propertyDetails?.forEach((element) => {
    //   let ids = element?._id;
    //   arr.push(ids);
    // });
    // console.log("params.PropertyIDs", arr);
    try {
      savedProperty({
        variables: {
          UserId: user?.data?._id,
          LeadSrc: "Property Save",
          LeadType: "Save",
          //RootId:"0",
          Comment: "",
          PropertyIDs: [item._id],
        },
      })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Property saved",
            variant: "success",
          });
        })
        .catch((err) => {
          const message = err && err.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  };
  const handleUnSaveProperty = () => {
    // alert("hello")
    try {
      unSavedProperty({
        variables: {
          userId: user?.data?._id,
          propertyId: item._id,
        },
      })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Property unsaved",
            variant: "success",
          });
        })
        .catch((err) => {
          const message = err && err.message;
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        });
    } catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  };

  function PreviousArrow(props) {
    const { onClick } = props;
    return (
      <img
        alt="prev"
        onClick={onClick}
        className="slick-prev"
        src="../assets/images/back.svg"
      />
    );
  }
  function NextArrow(props) {
    const { onClick } = props;
    return (
      <img
        alt="next"
        onClick={onClick}
        className="slick-next"
        src="../assets/images/forward.svg"
      />
    );
  }

  function getAltTag(item) {
    console.log("item", item);
    return `${item?.PID ?? ""}-${item?.ListBathRoom ?? ""}Bath-${
      item?.ListBedRoom ?? ""
    }Bed-${item?.Area ?? ""}`;
  }
  return (
    <div className="pl-item">
      <DialogBox
        open={openUnsaveMsg}
        setOpen={setOpenUnsaveMsg}
        handlePositiveBtn={handleUnSaveProperty}
        title={"Unsave Property?"}
        text={"Are you sure you want to Unsave the property?"}
      />
      <AuthPopUp open={openAuthPopUp} setOpen={setOpenAuthPopUp} />
      {!auth?.auth && item?.IsPrivate === true && !item?.RentalRegisteryId ? (
        <div className="not-registered-user">
          <p>
            Due to the recent rental registry law, you must be a registered user
            to access the details of this property.
          </p>
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              document.getElementById("frontend-header-login-btn").click()
            }
          >
            Register
          </Button>
        </div>
      ) : (
        <div
          className="pl-item-img"
          onClick={() => history.push(`/${item.PropertySlug}`)}
        >
          {auth.auth && (userRole === "owner" || userRole === "renter") && (
            <IconButton
              className="pl-fav"
              color="secondary"
              onClick={() => {
                isSaved() ? setOpenUnsaveMsg(true) : handleSaveProperty();
              }}
            >
              {isSaved() ? (
                <FavoriteIcon color="primary" />
              ) : (
                <FavoriteBorderIcon />
              )}
            </IconButton>
          )}

          {item?.RelatedPptyImages ? (
            <img
              src={item?.RelatedPptyImages}
              className="img-fluid"
              alt={getAltTag(item)}
            />
          ) : (
            <img
              src={"../assets/images/no-image.jpg"}
              className="img-fluid"
              alt={getAltTag(item)}
            />
          )}
        </div>
      )}
      <div
        className="pl-item-details"
        onClick={() => history.push(`/${item.PropertySlug}`)}
      >
        <h3>
          {item?.AdTitle}
          {/* {item?.AdTitle.charAt(0).toUpperCase() +
            item?.AdTitle.slice(1).toLowerCase()} */}
        </h3>
        <div class="lp-detail">
          <label>
            <img
              src="../assets/images/icon-location-outlined.svg"
              class="img-fluid"
              alt="Icon"
            />
            {item?.Area}
          </label>
          <div class="lp-rooms d-flex align-items-center">
            {item?.LivingArea && (
              <label>
                <img
                  src="../assets/images/icon-sqft.svg"
                  class="img-fluid"
                  alt="Icon"
                />
                {item?.LivingArea}
              </label>
            )}
            {item?.ListBedRoom && (
              <label>
                <img
                  src="../assets/images/icon-bed.svg"
                  class="img-fluid"
                  alt="Icon"
                />
                {item?.ListBedRoom}
              </label>
            )}
            {item?.ListBathRoom && (
              <label>
                <img
                  src="../assets/images/icon-bath.svg"
                  class="img-fluid"
                  alt="Icon"
                />
                {item?.ListBathRoom}
              </label>
            )}
          </div>
        </div>
      </div>
      <div className="pl-item-details pp-availability">
        <ul className="al-style propertyStyleTag">
          {item?.ListPool && <li>{item?.ListPool}</li>}
          {item?.ListHeat && <li>{item?.ListHeat}</li>}
          {item?.ListWater && <li>{item?.ListWater}</li>}
          {item?.ListAirCondition && <li>{item?.ListAirCondition}</li>}
          {item?.IsSouthOfHighway === true ? <li>South Of Highway</li> : null}
          {item?.IsJacuzziSpa === true ? <li>Jacuzzi Spa</li> : null}
          {item?.IsFurnished === true ? <li>Furnished</li> : null}
        </ul>
      </div>
    </div>
  );
}
